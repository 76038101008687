import React from 'react'
import { Box, Center, Checkbox, clsx, createStyles } from '@mantine/core'
import { styles } from '@/theme/styles'
import { observer } from 'mobx-react-lite'
import { numberFormat } from '@/shared/lib/number'
import { ComplectationEquipsModel } from '@/widget/complectation/models'

interface EquipProps {
    item: ComplectationEquipsModel['items'][0]
}

const useStyles = createStyles((theme) => ({
    root: {
        borderRadius: 12,
        padding: '8px 12px',
        fontSize: 14,
        lineHeight: '20px',
        cursor: 'pointer',
        transition: 'all .1s ease',
        display: 'flex',
        alignItems: 'center',
        background: styles.background,
        color: styles.primary,

        ':hover': {
            background: theme.fn.darken(styles.background, 0.04),
        },

        ':active': {
            background: theme.fn.darken(styles.background, 0.08),
        },
    },

    selected: {
        background: styles.blueLight,
        color: styles.blue,

        ':hover': {
            background: theme.fn.darken(styles.blueLight, 0.04),
        },

        ':active': {
            background: theme.fn.darken(styles.blueLight, 0.08),
        },
    },
}))

export const Equip: React.FC<EquipProps> = observer((props) => {
    const { item } = props

    const { classes } = useStyles()

    const priceFormat = numberFormat({ value: Math.abs(item.price) })

    return (
        <Box className={clsx(classes.root, { [classes.selected]: item.selected })} data-equip-id={item.id}>
            <Center sx={{ height: 24, width: 24, flexShrink: 0, pointerEvents: 'none' }} mr={12}>
                <Checkbox data-border-radio variant='option' size='sm' checked={item.selected} onChange={() => null} />
            </Center>

            <Box>{item.name}</Box>

            <Box ml={12} sx={{ whiteSpace: 'nowrap' }}>
                {item.price > 0 ? '+' : ''}

                {priceFormat}
            </Box>
        </Box>
    )
})
